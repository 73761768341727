import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"

const AnimatedLogo = () => {
  const [peopleInView, setPeopleInView] = useState(false)

  const changeLogo = () => {
    setPeopleInView(true)
  }

  useEffect(() => {
    setTimeout(() => {
      changeLogo()
    }, 1000)
  }, [])

  return (
    <div
      id="animated_logo"
      className="w-full my-auto z-10 mx-auto flex items-center justify-center md:h-[50vh]"
    >
      <StaticImage
        src="../../images/hero_main.png"
        alt="Logo"
        loading="eager"
        className="w-full inline-block z-10 md:w-2/3 md:h-auto mx-auto"
      />
      {peopleInView ? (
        <StaticImage
          src="../../images/hero_bg.png"
          alt="Logo - tło"
          loading="eager"
          className="w-full absolute md:w-2/3 z-0 md:h-[50vh]"
          quality={100}
        />
      ) : null}
    </div>
  )
}

export default AnimatedLogo
