import { Link } from "gatsby"
import React from "react"
import AnimatedLogo from "../@modules/hero/animatedLogo"

const Hero = () => {
  return (
    <div className="mt-5 w-full md:w-10/12 lg:w-8/12 max-w-xl mx-auto md:max-w-none">
      <div className="md:h-[50vh]">
        <AnimatedLogo />
      </div>

      <div className="px-4 my-10 md:px-0 md:grid md:grid-cols-[1fr,1px,1fr] md:gap-3 md:h-[50%] md:items-center md:my-16">
        <div className="md:text-right ">
          <p className="font-black text-3xl">
            Zapoznaj się z naszą deklaracją polityczną.
          </p>
          <Link
            to="deklaracja"
            target="_blank"
            rel="noreferrer noopener"
            className="bg-primary px-3 py-2 text-bg relative rounded-lg block w-max my-5 font-medium md:mx-0 md:ml-auto hover:scale-105 transition-all md:hover:mr-1"
          >
            Otwórz deklarację
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 inline"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
          </Link>
        </div>
        <div className="h-px w-1/3 my-5 bg-black md:w-px md:h-full md:mx-0" />
        <div>
          <span className="font-bold text-xl">Deklaracja</span>
          <p className="my-2">
            Zasady organizacyjne i programowe powołania wspólnej reprezentacji
            partii i ugrupowań politycznych na wybory parlamentarne 2023r. przy
            założeniu powołaniua wspólnego klubu w Sejmie i Senacie na całą
            kadencję. Komitet Wyborczy będzie nosił nazwę - Zjendoczeni Ponad
            Podziałami. Przyjęcie innych organizacji następuje poprzez
            głosowanie i uzyskanie 50% +1.
          </p>
          <Link
            to="deklaracja"
            target="_blank"
            rel="noreferrer noopener"
            className="text-link mt-1 underline"
          >
            Czytaj całość
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hero
