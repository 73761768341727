import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Link } from "gatsby"
const NewsComponent = () => {
  return (
    <>
      <div className="bg-primary text-bg py-10 mb-10 lg:px-0">
        <div className="mx-auto sm:px-2 lg:max-w-7xl lg:px-8 md:grid md:grid-cols-2 md:items-center justify-between md:gap-5">
          <div className="flex flex-col gap-2">
            <div className="bg-bg w-max text-primary rounded p-2 shadow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 inline"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
                />
              </svg>
            </div>
            <h2 className="font-black text-2xl italic">
              REFERENDUM{" "}
              <span className="font-medium block text-base">
                W sprawie zasobów naturalncych
              </span>
            </h2>

            <hr className="w-16" />
            <p className="spacing-1 tracking-wider">
              Wielka Koalicja - Zjednoczeni Ponad Podziałami grupująca już
              kilkadziesiąt organizacji poza parlamentarnych zwraca się z prośbą
              do wszystkich Polek i Polaków kochających swój Kraj, swoją rodzinę
              do tych którym na sercu leży dobro naszej Ojczyzny Polski o
              podjęcie niewielkiego wysiłku potwierdzającego uczucie miłości do
              tych wszystkich wartości jakie wyznaje nasza wspólnota. Mamy
              obowiązek uratowania naszych polskich zasobów naturalnych wartych
              biliony złotych byśmy my i nasi najbliżsi - dzieci, wnuki żyli
              godnie niezależnie od obcych wrogich nam od wieków nacji.
            </p>
            <Link
              to="/referendum"
              className="bg-bg text-primary w-max p-2 text-base rounded font-black"
            >
              Przeczytaj więcej
            </Link>
          </div>
          <div className="flex items-center relative max-w-lg aspect-square md:ml-auto">
            <StaticImage
              src="../images/karta.png"
              alt="Rogowo z lotu ptaka"
              className="mx-auto rounded ml-auto"
              imgClassName=""
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default NewsComponent
