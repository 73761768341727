import React from "react"

export const Candidate = ({
  eMail,
  phoneNumber,
  img,
  candidateName,
  org,
  main,
}) => {
  return (
    <div className="flex flex-row my-6 relative gap-1">
      <div className="w-[50px] h-[50px] md:w-[100px] md:h-[100px] flex items-center justify-center border-2 rounded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 md:h-8 md:w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>

      <div className="flex flex-col">
        <p className="font-medium">{candidateName}</p>
        <hr className="w-2/12 my-1" />
        <p className="text-primary font-medium">
          {main ? "PRZEWODNICZĄCY" : "WICEPRZEWODNICZĄCY"}
        </p>
        <p>{org ? "Organizacja: " + org : null}</p>
        <p>
          <a href={eMail ? eMail : null}>{eMail ? `E-mail: ${eMail}` : null}</a>
        </p>
        <p>
          <a href={"tel:+48" + phoneNumber ? phoneNumber : null}>
            {phoneNumber ? `Tel: ${phoneNumber}` : null}
          </a>
        </p>
      </div>
    </div>
  )
}
