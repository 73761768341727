import React from "react"
import LazyVideo from "./video"

const LeaderSpeech = () => {
  return (
    <div className="flex flex-col-reverse gap-3 md:gap-5 max-w-xl px-4 mx-auto md:max-w-5xl md:grid md:grid-cols-[1fr,1fr] md:items-center">
      <div>
        <header className="text-primary font-medium text-xl md:text-2xl">
          Polska znalazła się w dramatycznej sytuacji. <br />
          Potrzebny jest dialog społeczny wszystkich stron.
        </header>
        <p className="my-2 font-light md:text-justify">
          Apel Narodu Polskiego do Jarosława Kaczyńskiego i Donalda Tuska
          wygłoszony w dniu 12.07.2022 r. o wspólne rozpoczęcie i zorganizowanie
          prac przy umownie nazwanym Stole Ponad Podziałami. Trójstronne rozmowy
          były by podjęte przez z jednej strony rządzącą Prawicę, a drugiej
          Opozycję i z trzeciej strony Zjednoczonych Ponad Poidziałami
          reprezentujących stronę społeczną. Jest pilna potrzeba zawarcia
          kompromisu wobec wielopłaszczyznowego kryzysu i dobie konfliktu
          społecznego.
        </p>
        <a
          href="https://www.youtube.com/watch?v=6-k2omZukRg"
          target="_blank"
          rel="noopener noreferrer"
          className="text-link"
        >
          Obejrzyj w witrynie YouTube
        </a>
      </div>
      <LazyVideo
        src="https://www.youtube.com/embed/6-k2omZukRg"
        title="Apel Narodu Polskiego"
        styles="w-full aspect-video rounded-xl"
      />
    </div>
  )
}

export default LeaderSpeech
