import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Item = ({ id, text, image, mobileHidden }) => {
  return (
    <div
      key={id}
      className={mobileHidden ? `hidden md:block my-0` : `block my-0`}
    >
      <GatsbyImage
        image={getImage(image)}
        alt=""
        className="top-0 h-[200px] mb-2 rounded"
      />
      <div>
        <p className="text-justify" style={{ hyphens: "auto" }}>
          {id + ". " + text}
        </p>
      </div>
    </div>
  )
}

export default Item
