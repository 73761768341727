import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import downloadTwo from "../downloads/instytut-studiow-strategicznych.jpg"

const GetToKnowUs = () => {
  return (
    <div className="relative bg-primary text-bg pt-16 pb-20 px-4 sm:px-6 my-8 md:my-16 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Dowiedz się więcej o nas.
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Otwórz i przeczytaj zamieszczone preambuły, aby dowiedzieć się
            więcej o naszych działaniach.
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../images/Akcja Uwłaszczajaca.jpg"
                alt="Akcja Uwłaszczająca"
              />
            </div>
            <div className="flex-1 bg-bg p-6 flex text-black flex-col justify-between">
              <div className="flex-1 block mt-1">
                <p className="text-xl font-semibold text-gray-900">
                  Akcja Uwłaszczająca
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Uwłaszczymy Polaków na zasobach naturalnych. W tym celu
                  powołamy Spółkę "Polskie Zasoby Naturalne", która wyemituje
                  bezpłatne akcje uwłaszczające żeby zabezpieczyć je przed obcym
                  kapitałem.
                  <Link
                    to="akcja-uwlaszczajaca"
                    className="block mt-2 text-link hover:underline"
                  >
                    Czytaj dalej.
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../images/blogCard_2.jpg"
                alt="Instytut Studiów Strategicznych"
              />
            </div>
            <div className="flex-1 bg-bg p-6 flex text-black flex-col justify-between">
              <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">
                  Instytut Studiów Strategicznych
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Suwerenny pieniądz, Suwerenny bank centralny, suwerenna
                  architektura finansowa są gwarancją suwerennego państwa i
                  budowania dobrobytu obywateli.{" "}
                  <a
                    href={downloadTwo}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="block mt-2 text-link hover:underline"
                  >
                    Przeczytaj więcej.
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../images/blogCard_1.jpg"
                alt="Demokracja bezpośrednia"
              />
            </div>
            <div className="flex-1 bg-bg p-6 flex text-black flex-col justify-between">
              <div className="flex-1 block mt-2">
                <p className="text-xl font-semibold text-gray-900">
                  Demokracja bezpośrednia
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Demokracja Bezpośrednia gwarantuje każdemu polskiemu
                  obywatelowi praktyczny udział kontrolny nad poczynaniami
                  rządu.{" "}
                  <Link
                    to="demokracja-bezposrednia"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="block mt-2 text-link hover:underline"
                  >
                    Dowiedz się jak.
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetToKnowUs
