import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import LeaderSpeech from "../components/leaderSpeech"
import LeaderOverview from "../components/leaderOverview"
import ValuesOverview from "../components/valuesOverview"
import GetToKnowUs from "../components/getToKnowUs"
import CandidateMap from "../components/candidateMap"
import { StaticImage } from "gatsby-plugin-image"
import CandidateMapSenate from "../components/candidateMapSenate"
import NewsComponent from "../components/newsComponent"

const IndexPage = () => (
  <Layout>
    <Seo title="Strona Główna" />
    <Hero />
    <div className="mx-auto h-16 w-16 mb-16 flex items-center justify-center">
      {" "}
      <StaticImage
        className="h-16 w-16 bg-bg block z-10"
        src="../images/logo.svg"
        alt="przerywnik"
      />
      <hr className="absolute w-1/2 md:w-1/3 mx-auto opacity-40 " />
    </div>
    <NewsComponent />
    <LeaderSpeech />
    <ValuesOverview />
    <LeaderOverview />
    <GetToKnowUs />
    <CandidateMap />
    <CandidateMapSenate />
  </Layout>
)

export default IndexPage
