import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import valuesDATA from "../data/valuesOverview.json"
import Item from "../@modules/valuesOverview/item"

const ValuesOverview = () => {
  const [currentItem, setCurrentItem] = useState(valuesDATA[0])

  const data = useStaticQuery(graphql`
    query NonPageQueryDos {
      img_1: file(relativePath: { eq: "valuesOverview/1.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_2: file(relativePath: { eq: "valuesOverview/2.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_3: file(relativePath: { eq: "valuesOverview/3.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_4: file(relativePath: { eq: "valuesOverview/4.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_5: file(relativePath: { eq: "valuesOverview/5.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_6: file(relativePath: { eq: "valuesOverview/6.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_7: file(relativePath: { eq: "valuesOverview/7.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_8: file(relativePath: { eq: "valuesOverview/8.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_9: file(relativePath: { eq: "valuesOverview/9.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_10: file(relativePath: { eq: "valuesOverview/10.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_11: file(relativePath: { eq: "valuesOverview/11.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_12: file(relativePath: { eq: "valuesOverview/12.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_13: file(relativePath: { eq: "valuesOverview/13.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_14: file(relativePath: { eq: "valuesOverview/14.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const setNextItem = () => {
    if (currentItem.id !== 12) {
      return setCurrentItem(valuesDATA[currentItem.id++])
    } else {
      return null
    }
  }

  // const setPreviousItem = () => {
  //   if (currentItem.id !== 1) {
  //     return setCurrentItem(valuesDATA[0])
  //   } else {
  //     return null
  //   }
  // }

  return (
    <div className="py-12 bg-primary text-bg  my-8 md:my-16">
      <div className="mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 flex">
        <dl className="space-y-5 md:space-y-0 md:grid md:grid-cols-3 md:gap-8">
          <Item
            id={valuesDATA[currentItem.id - 1].id}
            text={valuesDATA[currentItem.id - 1].text}
            image={data[`img_${currentItem.id}`]}
          />
          <Item
            id={valuesDATA[currentItem.id].id}
            text={valuesDATA[currentItem.id].text}
            image={data[`img_${currentItem.id + 1}`]}
          />
          <Item
            id={valuesDATA[currentItem.id + 1].id}
            text={valuesDATA[currentItem.id + 1].text}
            image={data[`img_${currentItem.id + 2}`]}
          />
          <button
            className="mx-auto text-center w-full"
            onClick={() => setNextItem()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10 md:hidden mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </dl>
        <button onClick={() => setNextItem()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10 ml-2 sm:hidden md:block"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default ValuesOverview
