import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Candidate } from "../@modules/candidateMap/candidate"
import { useStaticQuery, graphql } from "gatsby"

const CandidateMapSenate = () => {
  const [current, setCurrent] = useState()

  const data = useStaticQuery(graphql`
    query NonPageQuerySenate {
      allStrapiKandydaciDoSenatu {
        nodes {
          okrag_senat {
            nazwa_okregu
            numer_okregu
          }
        }
      }
      allFile(
        filter: { relativeDirectory: { eq: "maps-senat" } }
        sort: { fields: name, order: ASC }
        limit: 102
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)

  const strapiCandidateDataSenat =
    data.allStrapiKandydaciDoSenatu.nodes[0].okrag_senat

  const imagesArrayOne = data.allFile.edges

  const setVoterCircle = e => {
    return setCurrent(strapiCandidateDataSenat[e - 1])
  }

  return (
    <div className="max-w-xl mx-auto px-4 md:max-w-none md:px-0 md:mx-none my-16 lg:w-2/3">
      <header className="text-center text-4xl font-bold mb-12">
        Okręgi Wyborcze do Senatu
      </header>
      <div className="md:grid grid-cols-2 md:mx-auto">
        <GatsbyImage
          image={getImage(
            imagesArrayOne[current ? current.numer_okregu : 0].node
          )}
          alt="Mapa"
          placeholder="NONE"
          className="m-4 md:w-full gap-2 md:mx-auto"
        />
        <div>
          <div>
            <label
              htmlFor="location"
              className="block font-medium text-gray-700 text-xl mb-1"
            >
              Wybierz okręg wyborczy
            </label>
            <select
              id="location"
              name="location"
              className="mt-1 bg-black text-bg block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md md:w-2/3"
              onChange={e => setVoterCircle(e.target.value)}
            >
              <option>Wybierz okręg z listy.</option>
              {strapiCandidateDataSenat.map(e => {
                return (
                  <option
                    id={e.numer_okregu}
                    key={e.numer_okregu}
                    value={e.numer_okregu}
                    className="cursor-pointer"
                  >
                    {"(" + e.numer_okregu + ") " + e.nazwa_okregu}
                  </option>
                )
              })}
            </select>
            <div>
              {current?.przewodniczacy ? (
                <Candidate
                  candidateName={current.kadndydat.imie_i_nazwisko}
                  org={current.kandydat.organizacja}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateMapSenate
