import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Link } from "gatsby"

const LeaderOverview = () => {
  return (
    <div className="px-4 h-fit max-w-xl md:flex md:flex-row md:px-0 mx-auto overflow-hidden md:w-10/12 lg:w-2/3 md:gap-2 md:max-w-4xl">
      <div className="my-auto">
        <StaticImage
          loading="lazy"
          src="../images/w-kornowski.jpeg"
          alt="Wojciech Kornowski"
          className="w-[100px] block mb-2 md:ml-4 rounded-lg md:w-[200px] lg:w-[200px]"
        />
      </div>

      <div>
        <header className="font-black text-4xl w-fit">
          Wojciech Kornowski
        </header>
        <hr className="w-3/12 my-1" />
        <span className="text-primary font-light">
          Przewodniczący Zjednoczeni Ponad Podziałami
        </span>
        <p className="md:text-justify leading-relaxed opacity-80 mt-2 font-light">
          Wojciech Kornowski to nietuzinkowa osoba. W latach 80-tych zbudował od
          podstaw wielobranżową spółdzielnie zatrudniającą 1200 osób, mającą
          przeszło 15 zakładów. Gdy Balcerowicz niszczył gospodarkę nadmiernym
          fiskalizmem, przekształcił spółdzielnie w kilka zakładów by ratować
          miejsca pracy.
          <br />
          <br /> W latach 90-tych uruchomił szpitali KORVITA ratujący 50 tys.
          ludziom życie i zdrowie. Szpital goszczący z Rosji najlepszych
          mikrochirurgów oka znany był z tego, że dokonywano cudów przywracając
          wzrok. Na ten temat napisano książkę i setki pozytywnych artykułów.
          <br />
          <Link
            to="/zyciorys/"
            target="_blank"
            rel="noreferrer noopener"
            className="text-link font-medium hover:underline"
          >
            Czytaj dalej.
          </Link>
        </p>
      </div>
    </div>
  )
}

export default LeaderOverview
