import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Candidate } from "../@modules/candidateMap/candidate"
import { useStaticQuery, graphql } from "gatsby"

const CandidateMap = () => {
  const [current, setCurrent] = useState()

  const data = useStaticQuery(graphql`
    query NonPageQuery {
      img_0: file(relativePath: { eq: "0.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_1: file(relativePath: { eq: "1.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_2: file(relativePath: { eq: "2.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_3: file(relativePath: { eq: "3.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_4: file(relativePath: { eq: "4.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_5: file(relativePath: { eq: "5.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_6: file(relativePath: { eq: "6.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_7: file(relativePath: { eq: "7.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_8: file(relativePath: { eq: "8.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_9: file(relativePath: { eq: "9.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_10: file(relativePath: { eq: "10.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_11: file(relativePath: { eq: "11.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_12: file(relativePath: { eq: "12.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_13: file(relativePath: { eq: "13.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_14: file(relativePath: { eq: "14.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_15: file(relativePath: { eq: "15.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_16: file(relativePath: { eq: "16.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_17: file(relativePath: { eq: "17.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_18: file(relativePath: { eq: "18.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_19: file(relativePath: { eq: "19.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_20: file(relativePath: { eq: "20.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_21: file(relativePath: { eq: "21.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_22: file(relativePath: { eq: "22.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_23: file(relativePath: { eq: "23.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_24: file(relativePath: { eq: "24.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_25: file(relativePath: { eq: "25.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_26: file(relativePath: { eq: "26.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_27: file(relativePath: { eq: "27.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_28: file(relativePath: { eq: "28.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_29: file(relativePath: { eq: "29.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_30: file(relativePath: { eq: "30.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_31: file(relativePath: { eq: "31.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_32: file(relativePath: { eq: "32.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_33: file(relativePath: { eq: "33.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_34: file(relativePath: { eq: "34.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_35: file(relativePath: { eq: "35.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_36: file(relativePath: { eq: "36.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_37: file(relativePath: { eq: "37.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_38: file(relativePath: { eq: "38.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_39: file(relativePath: { eq: "39.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_40: file(relativePath: { eq: "40.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img_41: file(relativePath: { eq: "41.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      allStrapiKandydaciDoSejmu {
        nodes {
          okrag {
            nazwa_okregu
            numer_okregu
            przewodniczacy {
              imie_i_nazwisko
              organizacja
            }
            wiceprzewodniczacy {
              id
              imie_i_nazwisko
              organizacja
            }
          }
        }
      }
    }
  `)

  const setVoterCircle = e => {
    return setCurrent(strapiCandidateDataSejm[e - 1])
  }

  const strapiCandidateDataSejm = data.allStrapiKandydaciDoSejmu.nodes[0].okrag

  return (
    <div className="max-w-xl mx-auto px-4 md:max-w-none md:px-0 md:mx-none md:my-16 lg:w-2/3">
      <header className="text-center text-4xl font-bold mb-12">
        Okręgi Wyborcze do Sejmu
      </header>
      <div className="md:grid grid-cols-2 md:mx-auto">
        <GatsbyImage
          image={getImage(data[`img_${current ? current.numer_okregu : 0}`])}
          alt="Mapa"
          placeholder="NONE"
          className="m-4 md:w-full gap-2 md:mx-auto"
        />
        <div>
          <div>
            <label
              htmlFor="location"
              className="block font-medium text-gray-700 text-xl mb-1"
            >
              Wybierz okręg wyborczy
            </label>
            <select
              id="location"
              name="location"
              className="mt-1 block bg-black text-bg w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md md:w-2/3"
              onChange={e => setVoterCircle(e.target.value)}
            >
              <option>Wybierz okręg z listy.</option>
              {strapiCandidateDataSejm.map(e => {
                return (
                  <option
                    id={e.numer_okregu}
                    key={e.numer_okregu}
                    value={e.numer_okregu}
                    className="cursor-pointer"
                  >
                    {"(" + e.numer_okregu + ") " + e.nazwa_okregu}
                  </option>
                )
              })}
            </select>
            <div>
              {current?.przewodniczacy ? (
                <Candidate
                  candidateName={current.przewodniczacy.imie_i_nazwisko}
                  org={current.przewodniczacy.organizacja}
                  main
                />
              ) : null}
              {current?.wiceprzewodniczacy ? (
                <Candidate
                  candidateName={current.wiceprzewodniczacy.imie_i_nazwisko}
                  org={current.wiceprzewodniczacy.organizacja}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateMap
